var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advisor-form"
  }, [_c('div', {
    staticClass: "advisor-form__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline black",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 1), _c('div', {
    staticClass: "advisor-form__box"
  }, [_c('div', {
    staticClass: "advisor-form__content"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('autenti-form', {
    attrs: {
      "hasAutenti": _vm.hasAutenti,
      "mode": "global",
      "showSaveButton": !_vm.isMarketplaceAccountant && _vm.hasAutenti
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }