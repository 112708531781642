















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import AutentiForm from '../components/AutentiForm.vue';
import ParticipantsTable from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/ParticipantsTable.vue';
import { mapGetters } from 'vuex'
import { GlobalSelects } from '@/modules/shared/dictionary-store/types';

@Component({
  components: {
    AutentiForm,
    EditorComponent,
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniRectButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, SygniLoader, ParticipantsTable },
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects',
    }),
  }
})
export default class AutentiFormModule extends Vue {
  globalSelects!: GlobalSelects;

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }
}

